import { ref } from 'vue'
import { FbCheckLoginStatus } from '../api'
import useFetchAccount from './useFetchAccount'
const isAuthenticate = ref(false)
export default () => {
  const { fetchAdAccount } = useFetchAccount()
  const checkLoginStatus = async () => {
    try {
      const { status } = await FbCheckLoginStatus()
      if (status === 'Y') {
        isAuthenticate.value = true
        fetchAdAccount()
        return
      }
      openAuthenticatedWindow()
    } catch (err) {
      window.alert('Fail, Please try again')
      console.error(err)
    }
  }

  const openAuthenticatedWindow = () => {
    const loginWindow = window.open(
      `https://connect.accunix.net/FBAD/login/index.php?orgID=9&uid=9&debug=Y`,
      'FB Login',
      'height=600, width=600'
    )
    window.addEventListener('message', function handler(e) {
      if (e.data.status === 'done') {
        isAuthenticate.value = true
        fetchAdAccount()
        loginWindow.close()
        window.removeEventListener('message', handler)
      }
      if (e.data.status === 'fail') {
        window.alert('Fail, Please Check Your Account')
        loginWindow.close()
        window.removeEventListener('message', handler)
      }
    })
  }

  return {
    isAuthenticate,
    openAuthenticatedWindow,
    checkLoginStatus,
  }
}
