import { FbAdSend } from '../api'
import { ref } from 'vue'
const isSending = ref(false)

export default (groupId) => {
  const sendAdLists = async () => {
    try {
      if (groupId.value === '') return window.alert('Fail, Audience Required')

      isSending.value = true
      const { Statuscode } = await FbAdSend({ aid: groupId.value })
      if (Statuscode === '200') window.alert('Success')
      if (Statuscode !== '200') throw new Error()
      isSending.value = false
    } catch (err) {
      isSending.value = false
      window.alert('Fail, Please try again')
      console.error(err)
    }
  }
  return { isSending, sendAdLists }
}
