import { ref } from 'vue'
import { FbGetAdAccount } from '../api'
const isAccountLoading = ref(false)
const accountLists = ref([])

export default () => {
  const fetchAdAccount = async () => {
    try {
      isAccountLoading.value = true
      const { Statuscode, data } = await FbGetAdAccount()
      if (Statuscode !== '200') throw new Error()
      isAccountLoading.value = false
      accountLists.value = data || []
    } catch (err) {
      isAccountLoading.value = false
      console.error()
    }
  }
  return { isAccountLoading, accountLists, fetchAdAccount }
}
