<template>
  <div class="bg-light position-relative">
    <div class="w-100 py-4">
      <div class="card m-auto shadow-sm w-50">
        <div class="card-body">
          <div class="py-4">
            <h4>Step 1: Login</h4>
            <button class="btn btn-primary btn-sm" @click="openAuthenticatedWindow">
              FB Authenticate
            </button>
          </div>
          <div class="position-relative">
            <div class="py-4">
              <h4>Step 2: Choose Account</h4>
              <div class="form-group">
                <label class="col-form-label">
                  <span class="text-danger">*</span>
                  Choose Account：
                </label>
                <select
                  class="form-select"
                  v-model="selectedAccount"
                  @change="fetchGroupLists"
                  :disabled="isGroupLoading"
                >
                  <option value="" disabled selected>
                    {{ isAccountLoading ? 'Loading...' : 'Please Choose Ad Account' }}
                  </option>
                  <option v-for="item of accountLists" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="py-4">
              <h4>Step 3: Choose Audience or Create New Audience</h4>
              <div class="form-group">
                <label class="col-form-label">
                  <span class="text-danger">*</span>
                  Choose Audience：
                </label>
                <select class="form-select" v-model="selectedGroup" placeholder="請選擇受眾包">
                  <option value="" disabled selected>
                    {{ isGroupLoading ? 'Loading...' : 'Please Choose Audience' }}
                  </option>
                  <option v-for="item of groupLists" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <button
                class="btn btn-primary mt-3"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                :disabled="selectedAccount === ''"
              >
                Create New Audience
              </button>
            </div>

            <div class="py-4">
              <h4>Step 4: Send</h4>
              <div class="p-3 m-3 border">
                Send Lists:
                <p v-for="(item, i) of fpcLists" :key="i" class="mb-1">{{ item }}</p>
              </div>
              <div class="text-center">
                <button class="btn btn-primary" @click="sendAdLists" :disabled="isSending">
                  Send
                </button>
              </div>
            </div>
            <div v-if="!isAuthenticate" class="position-absolute cover"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="" class="col-form-label">Enter Audience Name:</label>
              <input v-model="groupName" type="text" class="form-control" />
            </div>
            <div class="form-group">
              <label for="" class="col-form-label">Enter Audience Description</label>
              <input v-model="desc" type="text" class="form-control" />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              :disabled="isProcessing"
              @click="handleCreateGroup"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import useAuthenticate from '../composables/useAuthenticate'
import useGroup from '../composables/useGroup'
import useSendAd from '../composables/useSendAd'
import useFetchAccount from '../composables/useFetchAccount'
import { ref } from 'vue'

const selectedAccount = ref('')
const selectedGroup = ref('')
const { openAuthenticatedWindow, isAuthenticate } = useAuthenticate()
const { isAccountLoading, accountLists } = useFetchAccount()
const {
  groupName,
  desc,
  isProcessing,
  isGroupLoading,
  groupLists,
  fetchGroupLists,
  handleCreateGroup,
} = useGroup(selectedAccount, selectedGroup)

const { isSending, sendAdLists } = useSendAd(selectedGroup)
const fpcLists = ref([
  '000f02aef06e9a28e7135ad7359b0f98-_K3NNAQP3MPZGQ',
  '0010f6c3905d888e4a73f9ad6e1198ba-_KXD3YFHEKLWC7',
  '0010f6c3905d888e4a73f9ad6e1198ba-_KXD3YFHEKLWC7',
  '0010f6c3905d888e4a73f9ad6e1198ba-_KXD3YFHEKLWC7',
  '005f9f8561e05d2da295e8798bbc233e-_KSW181QEIN7Y1',
  '005fbd820058d292f051c025340384de-_KVYSZUNVY9W8B',
  '006b256f078ab99827a53362c9225429-_KW8G09Y4E449G',
  '008ac10d2da17d7aad7bfc6d6182efd7-_KVYNSFKN7SRHV',
  '008e4ad31ebc3e9ea05f2c66c6d2d9be-_KVYK2O493HH8L',
  '009f5bab9a505b88b63e3fd0dc034f4a-_KVYQZPWFK536L',
])
</script>

<style>
.cover {
  inset: 0 0 0 0;
  background: #ffffffcc;
}
</style>
